<template>
  <div>
    <header>

      <marquee scrollamount="5"> <i class="far fa-bullhorn fa-1x" /> ยินดีต้อนรับทุกท่านเข้าสู่ หวย
        เว็บไซต์ซื้อหวยออนไลน์อันดับ 1 ของไทย เรามีให้บริการทั้ง หวยรัฐบาล หวยลาว หวยมาเลย์ หวยฮานอย
        หวยหุ้นไทยและต่างประเทศ หวยยี่กี 264 รอบ พร้อมให้สมาชิกทุกท่านได้ร่วมสนุก ฝาก-ถอน รวดเร็ว ทันใจ ยินดีให้บริการตลอด
        24 ชั่วโมง ค่ะ <i class="far fa-bullhorn fa-flip-horizontal" /></marquee>
      <div class="text-center mb-1">
        <img
          src="Lo.png"
          alt="logo"
          height="50"
        >
      </div>
      <div class="row text-center pr-1 pl-1">
        <div class="col-6 d-flex align-items-center text-white action-bar">
          <img
            src="assets/ico/thb_icon.png"
            alt="thb"
            height="20"
            width="20"
          >&nbsp;
          <small> {{
            Commas(this.$store.state.appConfig.UserData.MainWallet)
          }}</small>
        </div>
        <div
          class="col-6 d-flex align-items-center justify-content-end text-white action-bar"
          @click="contactus()"
        >
          <i
            class="fab fa-line"
            style="color: #37BB22"
          />&nbsp;
          <small>ติดต่อเรา</small>
        </div>
      </div>
    </header>
    <div class="container mt-1 c-690">
      <div class="ac-top">
        <swiper
          ref="mySwiper"
          class="swiper-autoplay"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="data in swiperData"
            :key="data.img"
          >
            <b-img
              :src="data.img"
              fluid
            />
          </swiper-slide>
        </swiper>
        <div class="row gutters-10">
          <div class="col-10 col-sm-5 mb-20 d-none d-md-block">
            <div class="float-left mr-3">
              <strong>เครดิตคงเหลือ</strong><br><strong class="font-xl float-right">
                {{
                  Commas(this.$store.state.appConfig.UserData.MainWallet)
                }}
              </strong>
            </div>

          </div>
          <div class="col-2 col-sm-1 mb-20 pd-top-10 text-right d-none d-md-block" />
          <div class="col-md-6 mb-20">
            <div class="row gutters-10">
              <div class="col-6 pd-top-10">
                <router-link :to="{ name: 'withdraw' }">
                  <button
                    class="btn btn-info font-weight-bolder"
                    style="color: #000 !important"
                  >
                    <i class="fas fa-minus-circle" /> ถอนเครดิต
                  </button>
                </router-link>
              </div>
              <div class="col-6 pd-top-10">
                <router-link :to="{ name: 'deposit' }">
                  <button class="btn btn-primary font-weight-bolder">
                    <i class="fas fa-plus-circle" /> ฝากเครดิต
                  </button>
                </router-link>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-ac-list mb-20">
        <div class="row gutters-10">
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'lottery' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-arrow-down-9-1" />แทงหวย
              </div>
            </router-link>
          </div>
          <!-- <div class="col-4 col-md-2">
            <router-link :to="{ name: 'profile' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-user-gear" />ตั้งค่าบัญชี
              </div>
            </router-link>
          </div> -->
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'invite-friend' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-arrows-down-to-people" />ระบบแนะนำ
              </div>
            </router-link>
          </div>
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'status' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-money-bill-transfer" />รายงานฝาก/ถอน
              </div>
            </router-link>
          </div>
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'status' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-hand-holding-dollar" />รายงานเครดิต
              </div>
            </router-link>
          </div>
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'list-select' }">
              <div class="box-ac-items">
                <i class="fa-regular fa-rectangle-list" />รายการโพย
              </div>
            </router-link>
          </div>
          <div class="col-4 col-md-2">
            <router-link :to="{ name: 'reward' }">
              <div class="box-ac-items">
                <i class="fa-solid fa-list-ol" />ผลรางวัล
              </div>
            </router-link>
          </div>
          <!-- <div class="col-4 col-md-2">
            <router-link :to="{ name: 'profile' }">
              <div class="box-ac-items"><i class="fa-regular fa-envelope" />กล่องจดหมาย

              </div>
            </router-link>
          </div> -->
          <!-- <div class="col-4 col-md-2">
            <a
              data-target="#modal-contact"
              data-toggle="modal"
            >
              <div class="box-ac-items"><i class="fa-brands fa-weixin" />ติดต่อเอเย่นต์</div>
            </a>
          </div> -->
          <!-- <div class="col-4 col-md-2">
            <router-link :to="{ name: 'profile' }">
              <div class="box-ac-items color-red">
                <i class="fa-regular fa-newspaper" />ข่าว
              </div>
            </router-link>
          </div> -->
          <div class="col-4 col-md-2">
            <a onclick="confirmLogout()">
              <div class="box-ac-items color-red"><i class="fa-solid fa-right-from-bracket" />ออกจากระบบ</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
// BCarousel,
// BCarouselSlide,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper, SwiperSlide, BImg,
    // BCarousel,
    // BCarouselSlide,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/1.jpg') },
        { img: require('@/assets/images/2.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      },
      fields: [
        { key: 'round', label: 'รอบ' },
        { key: '3', label: '3ตัวบน' },
        { key: '2', label: '2ตัวล่าง' },
      ],
      items: [
        {
          round: 'หุ้นไทยเช้า',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเที่ยง',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยบ่าย',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเย็น',
          3: 'xxx',
          2: 'xx',
        },
      ],
      Result: {
        THG: {
          CloseDateName: '-',
          R1: '-',
          U3: '-',
          U2: '-',
          F3N1: '-',
          F3N2: '-',
          B3N1: '-',
          B3N2: '-',
          D2: '-',
        },
      },
    }
  },
  async mounted() {
    this.ShowWallet()
    const ReThg = await this.GetResult('THG', 'THG')
    if (ReThg) {
      this.Result.THG.CloseDateName = ReThg.RoundData.CloseDateName
      this.Result.THG.R1 = ReThg.R1
      this.Result.THG.U3 = ReThg.U3
      this.Result.THG.U2 = ReThg.U2
      this.Result.THG.F3N1 = ReThg.F3N1
      this.Result.THG.F3N2 = ReThg.F3N2
      this.Result.THG.B3N1 = ReThg.B3N1
      this.Result.THG.B3N2 = ReThg.B3N2
      this.Result.THG.D2 = ReThg.D2
    }
  },
  methods: {
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://webapi.linelotto.vip/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    // eslint-disable-next-line consistent-return
    async GetResult(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://webapi.linelotto.vip/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          return ResData.mes
        }
        return null
      } catch (e) {
        // console.log(e)
        return null
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.vhgf {
  margin-top: -5px;
  height: 40px;
  color: rgb(253, 253, 253);
  background-color: #7c7c7c;
}
.footers {
  margin-top: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  height: auto;
  width: 100vw;
  padding-top: 40px;
  color: #fff;
}
.img-country {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.balance {
  /* border: 0.1px solid #accb32; */
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  background-color: #414141;
  background-image: url(https://www.lotto4321.com/_nuxt/img/bg-credit.96c4543.png);
  /* background-position: right right; */
  /* border-radius: 10px; */
  background-size: cover;
  position: relative;
  z-index: unset;
}
body,
.txt-size {
  font-size: 1.2rem;
  color: #000;
}
html {
  padding: 0;
  margin: 0;
}
.img-mark {
  border-radius: 7px;
}
h3 {
  font-size: 1.8rem;
  font-weight: bold;
}
.header-bg {
  /* background: #fff url('/bgs.jpeg') repeat-y center center; */
  background-size: cover;
  padding: 4px;
}
/* .header-bg-content {
  background-color: #ffffff;
} */
.YrVDG {
  font-size: 1.125rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.bg-balance {
  padding: 12px;
  /* border: 1px solid #accb32; */
  /* border-radius: 10px; */
  /* box-shadow: 0px 3px 10px rgb(255, 110, 110); */
}

.bg-balance2 {
  border: 1px solid #accb32;
  border-radius: 10px;
  background-image: url(/images/bg-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.btn-menu {
  border: 1px solid #accb32;
  padding: 15px;
  /* border-radius: 10px; */
  color: #000;
  /* background-color: #accb32; */
}

.btn-menu2 {
  padding: 10px;
  color: rgb(0, 0, 0);
  background: #accb32;
}
.icon-l {
  color: #ffcc00;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
a {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
</style>
